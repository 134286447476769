import React from "react";
import * as Style from "./style";
import SizePeople from "../../assets/icons/eventIcons/SizePeople.png";
import Sizem2 from "../../assets/icons/eventIcons/Sizem2.png";
import Conference from "../../assets/icons/eventIcons/Conference.png";
import Drink from "../../assets/icons/eventIcons/Drink.png";
import OpeningHours from "../../assets/icons/eventIcons/OpeningHours.png";
import OpenDays from "../../assets/icons/eventIcons/Opendays.png";
import Dinner from "../../assets/icons/eventIcons/Dinner.png";

const EventIcon = ({ data }) => {
  const {
    kapacitet,
    english,
    area,
    PassarFor,
    tillgngligaDagar,
    tillgngligaTider
  } = data;
  
    return(
    <Style.EventIcons>
    {kapacitet ? (
      <div>
        <img src={SizePeople} alt="Amount poeple icon" />
        <p>{english ? `${kapacitet} Persons` : `${kapacitet} Personer`}</p>
      </div>
    ) : null}

    {area ? (
      <div>
        <img src={Sizem2} alt="m2 icon" />
        <p>{area}</p>
      </div>
    ) : null}

    {PassarFor.includes("Konferens") ? (
      <div>
        <img src={Conference} alt="Conference icon" />
        <p>{english ? "Conference" : "Konferens"}</p>
      </div>
    ) : null}

    {PassarFor.includes("Fest") ? (
      <div>
        <img style={{ width: "35px" }} src={Drink} alt="Drink icon" />
        <p>{english ? "Party" : "Fest"}</p>
      </div>
    ) : null}

    {PassarFor.includes("Middag") ? (
      <>
      <div>
        <img src={Dinner} alt="Dinner icon" />
        <p>{english ? "Dinner" : "Middag"}</p>
      </div>
      </>
    ) : null}

    {tillgngligaDagar  ? (
      <div>
        <img src={OpenDays} alt="OpeningHours icon" />
        <p>{`${tillgngligaDagar}`}</p>
      </div>
    ) : null}

    {tillgngligaTider ? (
      <div >
        <img src={OpeningHours} alt="OpeningHours icon" />
        <p>{`${tillgngligaTider}`}</p>
      </div>
      
    ) : null}
    </Style.EventIcons>
    )
};

export default EventIcon;

import styled from "styled-components";

export const EventIcons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items: center;
  margin: 5% 0 2% 0;
  @media(max-width: 920px){
    justify-content:space-evenly;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media(max-width: 920px){
      /* padding:10px 25px; */
      margin: 10px 0 0 2%;
    flex-grow: 1;
    width: calc(100% * (1/3) - 10px - 1px);
    }
    img {
      width: 40px;
      height:40px;
    }
    p {
      padding:5px 0 0 0;
      text-align: center;
      font-size: 12px;
      font-family: StureplansgruppenUnderRubrik;
    }
  }
`;
import React, { useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import App from "../pages/app";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import * as Style from "../css/templateStyle";
import { useGlobal } from "reactn";
import ReactMapGL, { Marker } from "react-map-gl";
import Carousel from "react-bootstrap/Carousel";
import getLang from "../components/scripts/getLang";
import mailIcon from "../assets/icons/mail.png";
import phoneIcon from "../assets/icons/phone-symbol-of-an-auricular-inside-a-circle.svg";
import "../../node_modules/mapbox-gl/src/css/mapbox-gl.css";
import EventIcons from "../components/eventIcons";

const LokalMap = ({ mapdata }) => {
  const [viewport, setViewport] = useState({
    latitude: mapdata.lat,
    longitude: mapdata.lon,
    width: "100%",
    height: "22vh",
    zoom: 13
  });
  return (
    <>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={
          "pk.eyJ1Ijoic3R1cmVwbGFuc2dydXBwZW4iLCJhIjoiY2p6NDdsZTNuMDB1YzNjdGRqa3pzYnd0MSJ9.ez4z7_BUlCjXJC20YWiyMA"
        }
        onViewportChange={viewport => {
          setViewport(viewport);
        }}
      >
        <Marker latitude={mapdata.lat} longitude={mapdata.lon}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: "30px" }} />
        </Marker>
      </ReactMapGL>
    </>
  );
};

const CarouselHero = ({ karusellBilder }) => {
  const karusellBilderMap = karusellBilder.map((image, index) => {
    return (
      <Carousel.Item key={index}>
        <Img fluid={image.fluid} />
      </Carousel.Item>
    );
  });
  return (
    <Carousel slide={true} touch={true} wrap={true}>
      {karusellBilderMap}
    </Carousel>
  );
};

const LokalPost = ({ data }) => {
  let [english] = useGlobal("English");
  data = getLang(english, data.allContentfulLokaler);

  const {
    adress,
    siteDescription,
    namn,
    kontaktEmail,
    lokalText,
    kapacitet,
    area,
    Passarfr,
    tillgngligaDagar,
    tillgngligaTider,
    kontaktTelefon,
    karusellBilder
  } = data[0].node;

  let konferens;
  let mingel;
  let middag;

  if (english === true) {
    konferens = Passarfr.includes("Konferens") ? " Conference" : null;
    mingel = Passarfr.includes("Fest") ? " Party" : null;
    middag = Passarfr.includes("Middag") ? " Dinner" : null;
  } else {
    konferens = Passarfr.includes("Konferens") ? " Konferens" : null;
    mingel = Passarfr.includes("Fest") ? " Fest" : null;
    middag = Passarfr.includes("Middag") ? " Middag" : null;
  }
  let arr = [];

  if (konferens) {
    arr.push(konferens);
  }
  if (mingel) {
    arr.push(mingel);
  }
  if (middag) {
    arr.push(middag);
  }
  arr = arr.toString();

  let PassarFor = Passarfr ? Passarfr : null;
  let description = siteDescription ? siteDescription : "";
  let phoneString = kontaktTelefon ? kontaktTelefon : null;
  let phoneHref = kontaktTelefon
    ? kontaktTelefon.replace("-", "").replace(/ /g, "")
    : null;
  let emailString = kontaktEmail ? kontaktEmail : null;

  return (
    <App>
      <SEO title={namn} description={description} />
      <Style.shortContainer className="newsPost">
        <Style.ArrowBack color={"black"}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => window.history.back()}
          />
        </Style.ArrowBack>
        <CarouselHero karusellBilder={karusellBilder} />
        <Style.PostContent>
          <h1>{namn}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: lokalText.childContentfulRichText.html
            }}
          />

          <EventIcons
            data={{
              kapacitet,
              area,
              tillgngligaDagar,
              tillgngligaTider,
              english,
              PassarFor
            }}
          />
        </Style.PostContent>

        <LokalMap mapdata={adress} />

        <Style.IconContainer>
          {emailString ? (
            <div>
              <a
                href={"mailto:" + emailString}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={mailIcon} alt="mailIcon" />
                <p>{emailString}</p>
              </a>
            </div>
          ) : null}
          {phoneString ? (
            <div>
              <a href={"tel:" + phoneHref}>
                <img src={phoneIcon} alt="phoneIcon" />
                <p>{phoneString}</p>
              </a>
            </div>
          ) : null}
        </Style.IconContainer>
      </Style.shortContainer>
    </App>
  );
};
export default LokalPost;

export const pageQuery = () => {
  return graphql`
    query ContentfulLokalPostBySlug($slug: String!) {
      allContentfulLokaler(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            node_locale
            siteDescription
            namn
            kontaktEmail
            slug
            kapacitet
            area
            Passarfr
            tillgngligaDagar
            tillgngligaTider
            kontaktTelefon
            lokalText {
              childContentfulRichText {
                html
              }
            }
            huvudbild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            karusellBilder {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            adress {
              lat
              lon
            }
          }
        }
      }
    }
  `;
};
